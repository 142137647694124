import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { signup, login } from "../services/AuthApi";

const initialState = {
  signupLoading: false,
  loginLoading: false,
  error: false,
  user: [],
};

export const signupUser = createAsyncThunk("user/signupUser", async (data) => {
  try {
    const res = await signup(data);
    return res;
  } catch (err) {
    return err;
  }
});

export const loginUser = createAsyncThunk("user/loginUser", async (data) => {
  try {
    const res = await login(data);
    return res;
  } catch (err) {
    return err;
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [signupUser.pending]: (state) => {
      state.signupLoading = true;
    },
    [signupUser.fulfilled]: (state, { payload }) => {
      state.signupLoading = false;
      state.user = payload?.data;
      console.log(payload);
    },
    [signupUser.rejected]: (state) => {
      state.signupLoading = false;
      state.error = true;
    },
    [loginUser.pending]: (state) => {
      state.loginLoading = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loginLoading = false;
      state.user = payload;
      console.log(payload);
    },
    [loginUser.rejected]: (state) => {
      state.loginLoading = false;
      state.error = true;
    },
  },
});

export default userSlice.reducer;
