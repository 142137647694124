import axios from "axios";

//const firebaseAuthID = localStorage.getItem("firebaseAuthID");

const TOKEN = localStorage.getItem("AccessToken");

export const createRestaurant = async (data) => {
  try {
    const res = await axios.post(
      "https://api.platterwise.com/jweb/create_restaurant.php",
      data
    );
    return res;
  } catch (error) {
    console.error("Error creating restaurant:", error);
    throw error;
  }
};

export const getAllRestaurants = async (data) => {
  const res = await axios.post(
    "https://api.platterwise.com/jweb/get_all_restaurant.php",
    data
  );
  return res;
};

export const getRestaurant = async (headers) => {
  const res = await axios.get(
    "https://api2.platterwise.com/web-api/restaurant/all",
    { headers }
  );
  return res; // You might want to return the data part of the response
};

const headers = {
  Authorization: `Bearer ${TOKEN}`,
  "Content-Type": "application/json",
};

// Export the function, not its result
export const GetRestaurantById = async () => {
  return await getRestaurant(headers);
};

export const editRestaurant = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };
    const res = await axios.post(
      "https://api2.platterwise.com/web-api/restaurant/edit-restaurant",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteRestaurant = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };
    const res = await axios.post(
      "https://api2.platterwise.com/web-api/restaurant/delete",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editCoverPic = async (data) => {
  const res = await axios.post(
    "https://api.platterwise.com/jweb/update_coverpic.php",
    data
  );
  return res;
};

export const viewMenuPic = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };
    const res = await axios.post(
      "https://api2.platterwise.com/web-api/restaurant/view",
      data,
      { headers }
    );
    console.log("menu", res);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editMenuPic = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };
    const res = await axios.post(
      "https://api2.platterwise.com/web-api/restaurant/edit-menu-picture",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteMenuPic = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };
    const res = await axios.post(
      "https://api2.platterwise.com/web-api/restaurant/delete-restaurant-picture",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteCoverPic = async (data) => {
  const res = await axios.post(
    "https://api.platterwise.com/jweb/delete_coverPix.php",
    data
  );
  return res;
};

export const deleteBannerPic = async (data) => {
  const res = await axios.post(
    "https://api.platterwise.com/jweb/delete_bannerPix.php",
    data
  );
  return res;
};
