import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import {
  seeReservation,
  approveReservation,
  cancelReservation,
  editReservation,
  checkInReservation,
  createReservationBill,
} from "../services/ReservationAPI";

const initialState = {
  addLoading: false,
  getLoading: false,
  seeReservation: [],
  error: false,
};

const TOKEN = localStorage.getItem("AccessToken");

export const seeReservationInfo = createAsyncThunk(
  "reservation/seeReservationInfo",
  async (data, { rejectWithValue }) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      };

      const res = await seeReservation(data, headers);
      console.log("seeReservation", res);
      return res.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const approveReservationInfo = createAsyncThunk(
  "reservation/approveReservationInfo",
  async (data, { rejectWithValue }) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      };

      const res = await approveReservation(data, headers);
      console.log("approveRes", res);
      return { ...res, reservation_id: data.reservation_id };
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const cancelReservationInfo = createAsyncThunk(
  "reservation/cancelReservationInfo",
  async (data, { rejectWithValue }) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      };

      const res = await cancelReservation(data, headers);
      return { ...res, reservation_id: data.reservation_id };
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const editReservationInfo = createAsyncThunk(
  "reservation/editReservationInfo",
  async (data, { rejectWithValue }) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      };
      const res = await editReservation(data, headers);
      console.log("editData", data);
      console.log("editRes", res);
      return {
        ...data,
        reservation_date: data?.reservation_date.toString(),
      };
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const checkInReservationInfo = createAsyncThunk(
  "reservation/checkInReservationInfo",
  async (data, { rejectWithValue }) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");

      const headers = {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      };

      const res = await checkInReservation(data, headers);
      return { ...res, reservation_id: data.reservation_id };
    } catch (err) {
      console.log(err);
    }
  }
);

export const createReservationBillInfo = createAsyncThunk(
  "reservation/createReservationBill",
  async (data, { rejectWithValue }) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");

      const headers = {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      };

      const res = await createReservationBill(data, headers);
      return { ...res, reservation_id: data.reservation_id };
    } catch (err) {
      console.log(err);
    }
  }
);

const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  extraReducers: {
    [seeReservationInfo.pending]: (state) => {
      state.getLoading = true;
    },
    [seeReservationInfo.fulfilled]: (state, { payload }) => {
      state.getLoading = false;
      state.seeReservation = payload?.data?.data;
      console.log(current(state));
    },
    [seeReservationInfo.rejected]: (state) => {
      state.getLoading = false;
      state.error = true;
    },
    [approveReservationInfo.pending]: (state) => {
      state.addLoading = true;
    },

    [approveReservationInfo.fulfilled]: (state, { payload }) => {
      const updatedReservations = state.seeReservation.map((reservation) => {
        if (reservation.id == payload?.reservation_id) {
          return {
            ...reservation,
            status: 2,
          };
        }
        return reservation;
      });

      return {
        ...state,
        addLoading: false,
        seeReservation: updatedReservations,
      };
    },

    [approveReservationInfo.rejected]: (state) => {
      state.addLoading = false;
      state.error = true;
    },
    [cancelReservationInfo.pending]: (state) => {
      state.addLoading = true;
    },

    [cancelReservationInfo.fulfilled]: (state, { payload }) => {
      const updatedReservations = state.seeReservation.map((reservation) => {
        if (reservation.id == payload?.reservation_id) {
          return {
            ...reservation,
            status: 0,
          };
        }
        return reservation;
      });

      return {
        ...state,
        addLoading: false,
        seeReservation: updatedReservations,
      };
    },

    [cancelReservationInfo.rejected]: (state) => {
      state.addLoading = false;
      state.error = true;
    },
    [checkInReservationInfo.pending]: (state) => {
      state.addLoading = true;
    },

    [checkInReservationInfo.fulfilled]: (state, { payload }) => {
      const updatedReservations = state.seeReservation.map((reservation) => {
        if (reservation.id == payload?.reservation_id) {
          return {
            ...reservation,
            status: 3,
          };
        }
        return reservation;
      });

      return {
        ...state,
        addLoading: false,
        seeReservation: updatedReservations,
      };
    },

    [createReservationBillInfo.pending]: (state) => {
      state.addLoading = true;
    },

    [createReservationBillInfo.fulfilled]: (state, { payload }) => {
      const updatedReservations = state.seeReservation.map((reservation) => {
        if (reservation.id == payload?.reservation_id) {
          return {
            ...reservation,
            status: 5,
          };
        }
        return reservation;
      });

      return {
        ...state,
        addLoading: false,
        seeReservation: updatedReservations,
      };
    },
    [createReservationBillInfo.rejected]: (state) => {
      state.addLoading = false;
      state.error = true;
    },

    [editReservationInfo.pending]: (state) => {
      state.addLoading = true;
    },

    [editReservationInfo.fulfilled]: (state, { payload }) => {
      const updatedReservations = state.seeReservation.map((reservation) => {
        if (reservation.id == payload?.reservation_id) {
          return {
            ...reservation,
            reservation_date: payload?.reservation_date,
          };
        }
        return reservation;
      });

      return {
        ...state,
        addLoading: false,
        seeReservation: updatedReservations,
      };
    },

    [editReservationInfo.rejected]: (state) => {
      state.addLoading = false;
      state.error = true;
    },
  },
});

export default reservationSlice.reducer;
