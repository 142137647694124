import React, { Suspense, lazy, useState } from "react";
import { ChakraProvider, theme, Box, Grid } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import IdleTimerContainer from "./Components/IdleTimer/IdleTimer";
import Logo from "./assets/images/Logo.png";
import Tabili from "./assets/images/Tabili.svg";

const Signup = lazy(() => import("./Components/Auth/Signup"));
const Login = lazy(() => import("./Components/Auth/Login"));
const CreateModal = lazy(() => import("./Components/Modals/CreateModal"));
const ForgotPassword = lazy(() => import("./Components/Auth/ForgotPassword"));
const OTP = lazy(() => import("./Components/Auth/Otp"));
const Dashboard = lazy(() => import("./Components/Dashboard/Dashboard"));
const Homepage = lazy(() => import("./Components/Homepage/Homepage"));
const ProtectedRoute = lazy(() => import("./Components/Auth/Protected"));

function App({ children }) {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="loader">
              <div className="loader__content">
                <img src={Tabili} alt="logo" />
              </div>
            </div>
          }
        >
          <Box textAlign="center" fontSize="xl">
            <Grid minH="100vh">
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/create-restaurant" element={<CreateModal />} />
                {/* <Route path="/login" element={<Login />} /> */}
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/otp" element={<OTP />} />
                {/* <Route element={<ProtectedRoute />}>
                  <Route path="/home" element={<Homepage />} />
                </Route> */}
                <Route path="/home" element={<Homepage />} />
                <Route path="/dashboard/:rest_id/*" element={<Dashboard />} />
              </Routes>
            </Grid>
          </Box>
        </Suspense>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
