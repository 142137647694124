import axios from "axios";
const TOKEN = localStorage.getItem("AccessToken");

export const seeReservation = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      "https://api2.platterwise.com/web-api/reservation/all",
      data,
      { headers }
    );

    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const approveReservation = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };
    const res = await axios.post(
      "https://api2.platterwise.com/web-api/reservation/approve",
      data,
      { headers }
    );
    console.log("RESAPPROVE", res);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const cancelReservation = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };
    const res = await axios.post(
      "https://api2.platterwise.com/web-api/reservation/cancel",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editReservation = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };
    const res = await axios.post(
      "https://api2.platterwise.com/web-api/reservation/edit",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const checkInReservation = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };
    const res = await axios.post(
      "https://api2.platterwise.com/web-api/reservation/check-in",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createReservationBill = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    };
    const res = await axios.post(
      "https://api2.platterwise.com/web-api/reservation/create-bill",
      data,
      { headers }
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
