import axios from "axios";
// const BASEURL = "";

// const instance = axios.create({
//   baseURL: BASEURL,
// });

export const signup = async (data) => {
  const res = await axios.post(
    "https://api2.platterwise.com/web-api/auth/register",
    data
  );
  return res;
};

export const login = async (data) => {
  const res = await axios.post(
    "https://api2.platterwise.com/web-api/auth/login",
    data
  );
  return res;
};

export const validateEmail = async (data) => {
  const res = await axios.post(
    "https://api2.platterwise.com/web-api/auth/validate-email",
    data
  );
  return res;
};

export const resetPassword = async (data) => {
  const res = await axios.post(
    "https://api2.platterwise.com/web-api/auth/reset-password",
    data
  );
  return res;
};

export const resetToken = async (data) => {
  const res = await axios.post(
    "https://api2.platterwise.com/web-api/auth/reset-token",
    data
  );
  return res;
};

export const getAuthInstance = async (data) => {
  const accessToken = localStorage.getItem("AccessToken");
  const authInstance = axios.create({
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return authInstance;
};

export const createRestaurant = async (data) => {
  const accessToken = localStorage.getItem("AccessToken");
  const instance = getAuthInstance(accessToken);
  const res = await instance.post(
    "https://api2.platterwise.com/web-api/restaurant/create",
    data
  );
  return res.data;
};
