import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import {
  getRestaurant,
  getAllRestaurants,
  createRestaurant,
  editRestaurant,
  deleteRestaurant,
  editCoverPic,
  editMenuPic,
  deleteMenuPic,
  deleteCoverPic,
  deleteBannerPic,
  GetRestaurantById,
  viewMenuPic,
} from "../services/CreateRestaurantApi";

const initialState = {
  getLoading: false,
  addLoading: false,
  allRestaurant: [],
  restaurants: [],
  menuPictures: [],
  error: false,
};

export const createRestaurantInfo = createAsyncThunk(
  "restaurant/createRestaurantInfo",
  async (data) => {
    console.log("data", data);
    try {
      const res = await createRestaurant(data);
      console.log(res);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getAllRestaurant = createAsyncThunk(
  "restaurant/getAllRestaurant",
  async (data) => {
    try {
      const res = await getAllRestaurants(data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

const TOKEN = localStorage.getItem("AccessToken");

export const getRestaurantById = createAsyncThunk(
  "restaurant/getRestaurantById",
  async (TOKEN, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
      };

      const res = await GetRestaurantById(headers);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const editRestaurantInfo = createAsyncThunk(
  "restaurant/editRestaurantInfo",
  async (data, { rejectWithValue }) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      };

      const res = await editRestaurant(data, headers);
      console.log(res);
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const deleteRestaurantInfo = createAsyncThunk(
  "restaurant/deleteRestaurantInfo",
  async (data, { rejectWithValue }) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      };

      const res = await deleteRestaurant(data, headers);
      console.log(res);
      return { ...res.data, ...data };
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const menuPicInfo = createAsyncThunk(
  "restaurant/menuPicInfo",
  async (data, { rejectWithValue }) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      };

      const res = await viewMenuPic(data, headers);
      console.log("menuRes", res);
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const editCoverPicInfo = createAsyncThunk(
  "restaurant/editCoverPicInfo",
  async (data) => {
    try {
      const res = await editCoverPic(data);
      return res;
      // console.log(res);
    } catch (err) {
      console.log(err);
    }
  }
);

export const editMenuPicInfo = createAsyncThunk(
  "restaurant/editMenuPicInfo",
  async (data, { rejectWithValue }) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      };
      const res = await editMenuPic(data, headers);
      console.log(res);
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const deleteMenuPicInfo = createAsyncThunk(
  "restaurant/deleteMenuPicInfo",
  async (data, { rejectWithValue }) => {
    try {
      const TOKEN = localStorage.getItem("AccessToken");
      const headers = {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      };

      const res = await deleteMenuPic(data, headers);
      console.log(res);
      return { ...res, restaurant_id: data.restaurant_id, id: data.id };
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const deleteCoverPicInfo = createAsyncThunk(
  "restaurant/deleteCoverPicInfo",
  async (data) => {
    try {
      const res = await deleteCoverPic(data);
      console.log(res);
      return { ...res, rest_id: data.rest_id };
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteBannerPicInfo = createAsyncThunk(
  "restaurant/deleteBannerPicInfo",
  async (data) => {
    try {
      const res = await deleteBannerPic(data);
      console.log(res);
      return { ...res, rest_id: data.rest_id };
    } catch (err) {
      console.log(err);
    }
  }
);

const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  extraReducers: {
    [getAllRestaurant.pending]: (state) => {
      state.getLoading = true;
    },
    [getAllRestaurant.fulfilled]: (state, { payload }) => {
      state.getLoading = false;
      state.allRestaurant = payload;
    },
    [getAllRestaurant.rejected]: (state) => {
      state.getLoading = false;
      state.error = true;
    },

    [getRestaurantById.pending]: (state) => {
      state.getLoading = true;
    },
    [getRestaurantById.fulfilled]: (state, { payload }) => {
      state.getLoading = false;
      state.restaurants = payload?.data?.data?.data;
      console.log(current(state));
    },
    [getRestaurantById.rejected]: (state) => {
      state.getLoading = false;
      state.error = true;
    },

    [createRestaurantInfo.pending]: (state) => {
      state.addLoading = true;
    },
    [createRestaurantInfo.fulfilled]: (state, { payload }) => {
      state.addLoading = false;
      state.getLoading = false;
      state.restaurants = payload;
      console.log(payload, current(state.restaurants));
      state.getLoading = false;
    },
    [createRestaurantInfo.rejected]: (state) => {
      state.addLoading = false;
      state.error = true;
    },
    [editRestaurantInfo.pending]: (state) => {
      state.addLoading = true;
    },
    [editRestaurantInfo.fulfilled]: (state, { payload }) => {
      state.addLoading = false;
      state.restaurants = [...state.restaurants, payload];
    },
    [editRestaurantInfo.rejected]: (state) => {
      state.addLoading = false;
      state.error = true;
    },
    [editCoverPicInfo.pending]: (state) => {
      state.addLoading = true;
    },
    [editCoverPicInfo.fulfilled]: (state, { payload }) => {
      state.addLoading = false;
      state.restaurants = [...state.restaurants, payload];
    },
    [editCoverPicInfo.rejected]: (state) => {
      state.addLoading = false;
      state.error = true;
    },
    [menuPicInfo.pending]: (state) => {
      state.addLoading = true;
    },
    [menuPicInfo.fulfilled]: (state, { payload }) => {
      state.addLoading = false;
      state.getLoading = true;
      state.menuPictures = payload?.data?.data?.menu_pic;
      console.log(current(state));
      state.getLoading = false;
    },
    [menuPicInfo.rejected]: (state) => {
      state.addLoading = false;
      state.error = true;
    },

    [editMenuPicInfo.pending]: (state) => {
      state.addLoading = true;
    },
    [editMenuPicInfo.fulfilled]: (state, { payload }) => {
      state.addLoading = false;
      state.menuPictures = [...state.menuPictures, payload];
    },
    [editMenuPicInfo.rejected]: (state) => {
      state.addLoading = false;
      state.error = true;
    },
    [deleteMenuPicInfo.pending]: (state) => {
      state.addLoading = true;
    },

    [deleteMenuPicInfo.fulfilled]: (state, { payload }) => {
      const { restaurant_id, id } = payload;

      // Filter the menuPictures array to remove the image with the specified id
      const updatedMenuPictures = state.menuPictures.filter((menuPicture) => {
        return !(
          menuPicture.restaurant_id === restaurant_id && menuPicture.id === id
        );
      });

      return {
        ...state,
        addLoading: false,
        menuPictures: updatedMenuPictures,
      };
    },

    [deleteMenuPicInfo.rejected]: (state) => {
      state.addLoading = false;
      state.error = true;
    },
    [deleteCoverPicInfo.pending]: (state) => {
      state.addLoading = true;
    },
    [deleteCoverPicInfo.fulfilled]: (state, { payload }) => {
      state.addLoading = false;
      state.restaurants = state.restaurants.map((restaurant) => {
        if (restaurant.rest_id === payload.rest_id) {
          return {
            ...restaurant,
            cover_pic: null, // or update with a default value
          };
        }
        return restaurant;
      });
    },
    [deleteCoverPicInfo.rejected]: (state) => {
      state.addLoading = false;
      state.error = true;
    },
    [deleteBannerPicInfo.pending]: (state) => {
      state.addLoading = true;
    },
    [deleteBannerPicInfo.fulfilled]: (state, { payload }) => {
      state.addLoading = false;
      state.restaurants = state.restaurants.map((restaurant) => {
        if (restaurant.rest_id === payload.rest_id) {
          return {
            ...restaurant,
            banner_pic: null, // or update with a default value
          };
        }
        return restaurant;
      });
    },
    [deleteBannerPicInfo.rejected]: (state) => {
      state.addLoading = false;
      state.error = true;
    },

    [deleteRestaurantInfo.pending]: (state) => {
      state.addLoading = true;
    },
    [deleteRestaurantInfo.fulfilled]: (state, { payload }) => {
      state.addLoading = false;
      state.restaurants = state.restaurants.filter(
        (restaurant) => restaurant.rest_id !== payload.rest_id
      );
    },
    [deleteRestaurantInfo.rejected]: (state) => {
      state.addLoading = false;
      state.error = true;
    },
  },
});

export default restaurantSlice.reducer;
