// import theme from "./Components/shared/Theme";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/css/auth.css";
import "./assets/css/dashboard.css";
import "./assets/css/form.css";
import "./assets/css/restaurant.css";
import "./assets/css/homepage.css";
import "./assets/css/restaurantdetails.css";
import "./assets/css/reservation.css";
import "./assets/css/reviews.css";
import "./assets/css/guests.css";
import "./assets/css/transaction.css";
import "./assets/css/team.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "./assets/css/MultiStepProgressBar.css";
import App from "./App";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/custompicker.css";
import "./assets/css/newdashboard.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
