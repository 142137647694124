import { configureStore } from "@reduxjs/toolkit";
import userInfo from "./userInfo";
import restaurantInfo from "./restaurantInfo";
import reservationInfo from "./reservationInfo";
// import { useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    userInfo,
    restaurantInfo,
    reservationInfo,
  },
});

// export const useAppDispatch = () => useDispatch();
